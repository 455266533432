ion-button {
  font-size: 0.9em ;
  padding: 0.5em 0 ;
}

ion-card-title {
  font-size: 1em ;
}

ion-input[type='date'] {
  width: 9em ;
}

ion-item {
  cursor: pointer ;
}

/* ion-loading.einweisungen_matrix {
  left: 10% ;
  position: absolute ;
  top: 3em ;
  width: 80% ;
} */

ion-modal {
  align-items: flex-start;
  justify-content: right;
  margin: 48px 16px;
}

ion-modal ion-card {
  height: 100% ;
}

ion-title {
  font-size: 1em ;
}

.eintrag_inaktiv {
  color: lightgray ; 
}

.mitarbeiter_bald_vertragsende {
  background-color: orange ; 
}